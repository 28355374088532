import * as React from 'react';
import { QuestionHeader } from '../';
import { useMediaQuery } from '../../modules/utils/hooks';
import { useSelector } from 'react-redux';
import { getQuestion } from '../../modules/selectors';

export const CompetitionHeaderPrefix = () => {
	const is_mobile = useMediaQuery('(max-width: 619px)');
	const question = useSelector(getQuestion);
	const title = is_mobile ? 'Competition' : 'Competition Question';
	const cms_title = is_mobile ? question.header_title_mobile : question.header_title;
	return (
		<QuestionHeader>
			{cms_title || title}
		</QuestionHeader>
	);
};

export default CompetitionHeaderPrefix;