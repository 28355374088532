import bindAll from "lodash/bindAll";
import React, { Component } from 'react';
import styled from "styled-components";
import theme from "../../assets/css/theme";
import media from '../../assets/css/media';
import { Checkbox, QuestionTagLine } from '../';
import get from 'lodash/get';
import map from 'lodash/map';
import values from 'lodash/values';
import toInteger from 'lodash/toInteger';
import { utagTrack } from "../../modules/utils/analytics";
import { connect } from 'react-redux';
import { submitDataCapture } from '../../modules/actions';
import arrow from '../../assets/img/icons/angle-arrow-down.png';
import { getQuestion } from '../../modules/selectors';
import { IStoreState } from '../../modules/types';
import { IQuestionReducerState } from '../../modules/reducers/question';

interface IDispatchToProps {
	submitDataCapture: typeof submitDataCapture
}
interface IOwn extends IDispatchToProps {
	is_open: boolean,
	question: IQuestionReducerState,
}

const Fields = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
`;

const ButtonStyled = styled.input`
	padding: 0.75em 1em;
	text-decoration: none;
	text-align: center;
	border-radius: 2px;
	letter-spacing: 0.5px;
	font: 16px 'The Sun', sans-serif;
	font-weight: 500;
	color: #FFFFFF;
	width: 100%;
	margin: auto auto 15px;
	display: block;
	background-color: ${({theme}) => theme.color};
	border: none;
	outline: none;
	cursor: pointer;
	-webkit-appearance: none;
	&:disabled {
		opacity: .5;
		cursor: default;
	}
	@media (max-width: ${media.mobile}) {
		margin: 0;
	}
`;

const Input = styled.input`
	padding: 0 15px;
	margin-bottom: 12px;
	color: #222526;
	font-family: "The Sun";
	outline: none;
	width: 49%;
	&::-webkit-input-placeholder {
		color: #222526;
	}
	&::-moz-placeholder {
		color: #222526;
	}
	&:-ms-input-placeholder {
		color: #222526;
	}
	&:-moz-placeholder {
		color: #222526;
	}
	@media (max-width: ${media.mobile}) {
		&.email {
			width: 100%;
			margin-right: 0;
		}
	}
`;
const SelectWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 49%;
	margin-bottom: 12px;
	@media (max-width: ${media.mobile}) {
		width: 100%;
		margin-bottom: 8px;
	}
	> div {
		width: 50%;

		&:first-child {
			margin-right: 8px;
		}
	}
`;
const SelectWithArrowWrapper = styled.div`
	background: url(${arrow}) no-repeat right 15px center;
	background-size: 10px;
`;

const Select = styled.select`
	appearance: none;
	padding: 0 15px;
	background: transparent;
	color: #222526;
	outline: none;
	width: 100%;
	&.empty {
		color: #222526;
	}
`;
const DataCaptureWrapper = styled.form`
	height: calc(100% - 15px);
	width: 100%;
	font-family: ${theme.base_bold_font};
	justify-content: flex-start;
	position: relative;
	background: #fff;
	padding: 0;
	display: flex;
	flex-direction: column;
	
	p {
		color: #222526;
        font-family: 'The Sun';
		font-size: 10px;
		line-height: 16px;
		@media (max-width: ${media.mobile}) {
			line-height: 14px;
		}
	}
	
	a {
		color: #6E7070;
	}
	
	${Input},
	${Select} {
		border: 1px solid #ADAFB0;
		border-radius: 4px;
		height: 48px;
		font-size: 16px;
		@media (max-width: ${media.mobile}) {
			font-size: 14px;
			height: 40px;
		}
	}
	
	${QuestionTagLine} {
		margin-bottom: 15px;
		@media (max-width: ${media.mobile}) {
			font-size: 22px;
		}
	}
`;

const Label = styled.div`
	transition: .4s;
	min-height: 26px;
	position: relative;
	text-align: left;
	box-sizing: border-box;
	width: 100%;
	color: #222526;
	font-family: "The Sun";
	font-size: 12px;
	line-height: 16px;
	@media (max-width: ${media.mobile}) {
		font-size: 10px;
		align-items: flex-start;
	}
	a {
		color: #222526;
		text-decoration: underline;
		font-family: ${theme.base_bold_font};
	}
`;

interface IState {
	keys: {
		first_name: string,
		last_name: string,
		email: string,
		gender: string,
		age: string
		terms: string,
		marketing_opt_in: string;
		marketing_other: string;
	},
	errors: {
		first_name?: boolean,
		last_name?: boolean,
		email?: boolean,
		gender?: boolean,
		age?: boolean
		terms?: boolean,
		marketing_opt_in?: boolean;
		marketing_other?: boolean;
	},
	disabled: boolean
}

class DataCaptureComponent extends Component<IOwn, IState> {
	public state = {
		keys: {
			first_name: '',
			last_name: '',
			email: '',
			gender: '',
			age: '',
			terms: '1',
			marketing_opt_in: '0',
			marketing_other: '0'
		},
		errors: {
			first_name: true,
			last_name: true,
			email: true,
			gender: true,
			age: true,
			terms: false,
			marketing_opt_in: false,
			marketing_other: false
		},
		disabled: true
	};
	constructor(props: IOwn) {
		super(props);

		bindAll(this, [
			'senAnalytics',
			'onChangeForm',
			'handleSubmitForm',
			'isValid'
		])
	}

	componentDidMount(): void {
		this.checkErrors();
		this.senAnalytics('displayed');

	}

	public senAnalytics(event: string) {
		const {question} = this.props;
		utagTrack({
			event_action: "competition entry form " + event,
			question_type: question.question_type
		}, 'competition');
	}

	public render(): React.ReactNode {

		const { is_open } = this.props;

		if (!is_open) {
			return null;
		}
		const { keys, disabled } = this.state;
		return (
			<DataCaptureWrapper onChange={this.onChangeForm} onSubmit={this.handleSubmitForm}>
				<QuestionTagLine>
					Enter your details for a chance to win the amazing holiday for four
				</QuestionTagLine>
				<Fields>
					<Input type="text" name="first_name" className="first-name" placeholder="First name" defaultValue={keys.first_name} required={true} />
					<Input type="text" name="last_name" placeholder="Last name" defaultValue={keys.last_name} required={true} />
					<Input type="email" name="email" className="email" placeholder="Email" defaultValue={keys.email} required={true} />
					<SelectWrapper>
						<SelectWithArrowWrapper>
							<Select name="gender" id="gender" defaultValue={keys.gender} className={keys.gender === '' ? 'empty' : ''}>
								<option value="">Gender</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
								<option value="prefer_not_say">Prefer not to say</option>
							</Select>
						</SelectWithArrowWrapper>
						<SelectWithArrowWrapper>
							<Select name="age" id="age" defaultValue={keys.age} className={keys.age === '' ? 'empty' : ''}>
								<option value="">Age</option>
								<option value="under 18">Under 18</option>
								<option value="18-25">18-25</option>
								<option value="26-35">26-35</option>
								<option value="36-45">36-45</option>
								<option value="46-55">46-55</option>
								<option value="56-65">56-65</option>
								<option value="65 or over">Above 65</option>
							</Select>
						</SelectWithArrowWrapper>
					</SelectWrapper>
				</Fields>

				<Label>
					UK/Ire residents only. Promoter: News Group Newspapers Ltd. By pressing ‘Submit’ you agree to <a href="https://www.thesun.co.uk/competitions/9231054/the-sun-tv-showbiz-predictor-the-competition-online-rules/"
					   target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
				</Label>

				<Checkbox
					name="marketing_opt_in"
					onChange={this.onChangeForm}
					defaultChecked={false}
					defaultValue={keys.marketing_opt_in}
				>
					Select this to be informed about exclusive offers, promotions and events from The Sun
				</Checkbox>
				<Checkbox
					name="marketing_other"
					onChange={this.onChangeForm}
					defaultChecked={false}
					defaultValue={keys.marketing_other}
				>
					Select this to receive information on special promotions and products from Netflights
				</Checkbox>
				<ButtonStyled type="submit" disabled={disabled} value="Submit" />
			</DataCaptureWrapper>
		)
	}

	public onChangeForm(e: React.SyntheticEvent<EventTarget>): void {
		const is_checkbox = get(e.target, 'type') === 'checkbox';
		const target = e.target;

		const name = get(target, 'name', '');
		const { keys } = this.state;
		let value: string;

		if (is_checkbox) {
			value = get(target, 'checked', 0) ? "1" : "0";
		} else {
			value = get(target, 'value', '');
		}

		this.setState({
			keys: {
				...keys,
				[name]: value,
			}
		}, () => this.checkErrors());

	}
	private isValid() {
		const is_valid = !values(this.state.errors).includes(true);
		this.setState({
			disabled: !is_valid
		});
	}
	private checkErrors() {
		const { keys } = this.state;
		const errors = {};

		map(keys, (value: string, key: string) => {
			if (key === 'marketing_opt_in' || key === 'marketing_other') {
				errors[key] = false;
			}
			else if (key === 'terms') {
				errors[key] = !toInteger(value)
			}
			else {
				errors[key] = value === '';
			}

		});
		this.setState({
			errors,
		}, () => this.isValid());

		return errors;
	}

	/**
	 * Method to process data capture form submission
	 */
	public handleSubmitForm(e: React.SyntheticEvent<EventTarget>): void {
		e.preventDefault();
		const { submitDataCapture } = this.props;
		const { keys } = this.state;
		const {
			first_name,
			last_name,
			email,
			gender,
			age,
			marketing_opt_in,
			marketing_other
		} = keys;

		const is_valid = !this.state.disabled;

		if (is_valid) {
			submitDataCapture({
				first_name,
				last_name,
				email,
				gender,
				age,
				marketing_opt_in,
				marketing_other
			});
			this.senAnalytics('submitted');

		}

	};
}
interface IMap {
	question: IQuestionReducerState,
}
const mapStateToProps = (state: IStoreState): IMap => ({
	question: getQuestion(state),
});
const mapDispatchToProps: IDispatchToProps = ({
	submitDataCapture
});

export const DataCapture = connect(mapStateToProps, mapDispatchToProps)(DataCaptureComponent);

export default DataCapture;