import styled from 'styled-components';

export const OptionsWrapper = styled.div`
	height: 245px;
	display: flex;
	flex-direction: column;
	> div {
		flex: 1;
	}
`;

export default OptionsWrapper;