import { createReducer } from 'redux-act';
import { setScreenToShow, setRequiredScreenToShow } from '../../actions';

export interface ICompetition {
	screen_to_show: string;
	required_screen_to_show: string;
}

const initial_state = {
	screen_to_show: '',
	required_screen_to_show: ''
};

export const competition = createReducer<ICompetition>({}, initial_state)
	.on(setScreenToShow, (state, payload) => ({
		...state,
		...payload
	}))
	.on(setRequiredScreenToShow, (state, payload) => ({
		...state,
		...payload
	}));