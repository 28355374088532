export default {
	primary: '',
	spinner: '#d4d4d4',
	secondary: '',
	body_bg: '',
	content_width: '',
	base_text_color: '',
	second_text_color: '',
	base_font: 'The Sun',
	base_bold_font: 'The Sun Heavy Narrow',
	secondary_bold_font: 'The Sun Heavy Narrow',
	buttons_color: '',
	mobile_menu_font: '',
	mobile_menu_color: '',
	header_bg: '',
	ladder_bg_item: '',
	buttons_background: '',
	second_buttons_color: '',
	action_buttons_color: '',

	confirm_modal_cover_bg: '',
	confirm_modal_container_bg: '',

	icon_color: '',
	height: ''
};
