import styled, { css } from "styled-components";

interface IModelWrapper {
	readonly is_open?: boolean
}

export const ModalWrapper = styled.div<IModelWrapper>`
	position: absolute;
	top: -9999px;
	display: flex;
	width: 100%;
	height: 480px;
	z-index: 4;
	background: ${props => props.theme.color};
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: all 0.3s linear;
	transition-property: max-height, opacity, max-width;
	overflow: hidden;
	${({is_open}) => is_open && css`
		opacity: 1;
		position: relative;
		top: 0;
	`}
`;
export default ModalWrapper
