import React, { lazy } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {
	BrowserRouter,
	Route,
	Switch
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import core from './assets/css/core';
import {
	GlobalErrors,
	ScrollToTop,
	PostMessageListener,
	ErrorBoundary,
	LoadingSpinner
} from './components'
import store from './modules/store';

const font_path = process.env.REACT_APP_FONT_PATH;
const Polling = lazy(() => import('./pages/Polling'));
const YCI = lazy(() => import('./pages/YCI'));
const Competition = lazy(() => import('./pages/Competition'));

const GlobalStyle = createGlobalStyle`
	@font-face {
	    font-family: 'The Sun';
	    src: url(${font_path}assets/fonts/the-sun/TheSun-Regular.woff2) format('woff2');
	    font-weight: normal;
	    font-style: normal;
	}
	
	@font-face {
	    font-family: 'The Sun Heavy Narrow';
	    src: url(${font_path}assets/fonts/the-sun/TheSun-HeavyNarrow.woff2) format('woff2');
	    font-weight: 900;
	    font-style: normal;
	}
	@font-face {
	    font-family: 'The Sun';
	    src: url(${font_path}assets/fonts/the-sun/TheSun-Medium.woff2) format('woff2');
	    font-weight: 500;
	    font-style: normal;
	}
	${core}
`;

render(
	<Provider store={store}>
		<ErrorBoundary>
			<GlobalStyle/>
			<BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
				<PostMessageListener>
					<ScrollToTop>
						<React.Suspense fallback={(<LoadingSpinner with_cover_bg={true} position="center" />)}>

							<Switch>
								<Route path="/polling" component={Polling}/>
								<Route path="/yci" component={YCI}/>
								<Route path="/competition" component={Competition} />
							</Switch>
							<GlobalErrors/>
						</React.Suspense>
					</ScrollToTop>
				</PostMessageListener>

			</BrowserRouter>
		</ErrorBoundary>
	</Provider>,
	document.getElementById('root')
);

// registerServiceWorker();
