import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default createStore(
	combineReducers(reducers as any),
	applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(rootSaga);