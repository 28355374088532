import bindAll from "lodash/bindAll";
import React, { Component } from 'react';
import styled from "styled-components";
import theme from "../../assets/css/theme";
import { ModalWrapper, QuestionFooter } from '../';
import { utagTrack } from "../../modules/utils/analytics";
import { connect } from 'react-redux';
import media from '../../assets/css/media';
import { IQuestionReducerState } from '../../modules/reducers/question';
import { IStoreState } from '../../modules/types';
import { getQuestion } from '../../modules/selectors';

interface IOwn {
	is_open: boolean,
	question: IQuestionReducerState;
}

const Title = styled.h3`
	text-align: center;
	font-size: 24px;
	line-height: 33px;
	font-family: ${theme.secondary_bold_font};
	margin-bottom: 44px;
	margin-top: 50px;
`;

const ButtonStyled = styled.a`
	padding: 0 1em;
	text-decoration: none;
	text-align: center;
	border-radius: 2px;
	letter-spacing: 0.5px;
	font: 16px 'The Sun', sans-serif;
	font-weight: 500;
	width: 100%;
	display: flex;
	height: 40px;
	background-color: #fff;
	color: #9C73C1;	border: none;
	outline: none;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	@media (max-width: ${media.desktop}) {
		font-size: 14px;
	}
`;

const CongratsWrapper = styled.div`
	height: 100%;
	width: 100%;
	font-family: ${theme.base_bold_font};
	justify-content: flex-start;
	position: relative;
	background: #6A4E82;
	color: #fff;
	padding: 15px 20px 0;
`;

const Description = styled.p`
	text-align: center;
	font-size: 15px;
	font-weight: bold;
	line-height: 20px;
	margin-bottom: 60px;
	font-family: "The Sun";
`;

const ModalWrapperStyled = styled(ModalWrapper)`
	background: #fff;
	padding: 0;
	width: 100%;
	box-sizing: border-box;
	flex-direction: column;
`;

class CongratsComponent extends Component<IOwn> {

	constructor(props: IOwn) {
		super(props);

		bindAll(this, [
			'senAnalytics'
		])
	}

	public componentDidMount(): void {
		const { question } = this.props;
		utagTrack({
			event_action: "competition entry confirmation",
			question_type: question.question_type
		}, 'yci');
	}

	public senAnalytics() {
		const { question } = this.props;

		utagTrack({
			event_action: "go to island page : predictor",
			question_type: question.question_type
		}, 'yci');
	}
	public get footer() {
		return <QuestionFooter game="yci" align="center" is_show_stats={false} />;
	}
	public render(): React.ReactNode {

		const { is_open } = this.props;

		if (!is_open) {
			return null;
		}
		return (
			<ModalWrapperStyled is_open={is_open}>
				<CongratsWrapper>
					<Title>
						All done!
					</Title>

					<Description>
						If your prediction is correct, you’ll be entered into the grand draw.
						<br/><br/>
						Make more predictions throughout the series to increase your chances (max one entry per day)
					</Description>

					<ButtonStyled href="https://www.thesun.co.uk/tvandshowbiz/love-island/" target="_parent" onClick={this.senAnalytics}>
						Latest Love Island news
					</ButtonStyled>
				</CongratsWrapper>

				{this.footer}
			</ModalWrapperStyled>
		)
	}
}
interface IMap {
	question: IQuestionReducerState,
}
const mapStateToProps = (state: IStoreState): IMap => ({
	question: getQuestion(state),
});
export const Congrats = connect(mapStateToProps)(CongratsComponent);

export default Congrats;