import isString from 'lodash/isString';
import { SagaIterator } from 'redux-saga';
import { put }          from 'redux-saga/effects';
import * as actions from  '../../actions'
import { ISagaAction }  from '../../types';

export const errorsManagerSaga = function* (action: ISagaAction<any>): SagaIterator {
	try {
		const error = action.payload;

		if(error instanceof TypeError) {
			return;
		}
		if(isString(error)){
			throw error;
		}
		if(error && isString(error.message)) {
			throw error.message
		}
	} catch (e) {
		yield put(actions.errorsShowGlobalError({ text: e }));
	}
};