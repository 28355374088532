import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestion } from '../../modules/selectors';
import { setScreenToShow } from '../../modules/actions';
import {CompetitionButton, QuestionTagLine, CompetitionHeaderPrefix } from '../';
import media from '../../assets/css/media';


const QuestionTagLineStyled = styled(QuestionTagLine)`
	font-size: 30px;
	@media (max-width: ${media.height_break_point}) {
		font-size: 24px;
	}
`;

const SubHeader = styled.p`
	color: #222526;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: -0.23px;
	line-height: 28px;
	margin-bottom: 15px;
	@media (max-width: ${media.height_break_point}) {
		font-size: 14px;
	}
`;

const ImageWrapper = styled.div<{image_src: string}>`
	width: 100%;
	height: 50%;
	max-height: 183px;
	background: url(${({image_src}) => image_src}) center no-repeat;
	background-size: cover;
	margin-bottom: 20px;
`;

const CompetitionButtonStyled  = styled(CompetitionButton)`
	width: 130px;
	display: block;
	margin: 0 auto;
`;

export const SplashScreen = () => {
	const question = useSelector(getQuestion);
	const dispatch = useDispatch();
	const showOptions = () => dispatch(setScreenToShow({screen_to_show: 'options'}));
	const { splash_page: { promo_text, promo_sub_header, image } } = question;
	const img_url = process.env.REACT_APP_IMAGE_URL;

	const getImageURL =  (image: string) => {
		if (~image.indexOf('data:image')) {
			return image;
		}
		return img_url + image;
	};
	return (
		<React.Fragment>
			<QuestionTagLineStyled>
				<CompetitionHeaderPrefix />
				{promo_text}
			</QuestionTagLineStyled>
			<SubHeader>
				{promo_sub_header}
			</SubHeader>

			<ImageWrapper image_src={getImageURL(image)} />

			<CompetitionButtonStyled onClick={showOptions}>Enter now</CompetitionButtonStyled>
		</React.Fragment>
	)
};

export default SplashScreen;