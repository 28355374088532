import bindAll from "lodash/bindAll";
import React, { Component } from 'react';
import styled from "styled-components";
import theme from "../../assets/css/theme";
import { ModalWrapper } from '../';
import { utagTrack } from "../../modules/utils/analytics";
import { connect } from 'react-redux';
import { submitPermission } from '../../modules/actions';
import media from '../../assets/css/media';
import { IQuestionReducerState } from '../../modules/reducers/question';
import { IStoreState } from '../../modules/types';
import { getQuestion } from '../../modules/selectors';

interface IDispatchToProps {
	submitPermission: typeof submitPermission
}
interface IOwn extends IDispatchToProps {
	is_open: boolean;
	question: IQuestionReducerState;
}

const Title = styled.h3`
	text-align: center;
	font-size: 24px;
	line-height: 33px;
	font-family: ${theme.secondary_bold_font};
	margin-bottom: 44px;
	margin-top: 50px;
`;

const ButtonStyled = styled.button`
	padding: 0 1em;
	text-decoration: none;
	text-align: center;
	border-radius: 2px;
	letter-spacing: 0.5px;
	font: 16px 'The Sun', sans-serif;
	font-weight: 500;
	color: #FFFFFF;
	width: 130px;
	display: block;
	height: 40px;
	background-color: #9C73C1;
	border: none;
	outline: none;
	cursor: pointer;
	box-sizing: border-box;
	@media (max-width: 321px) {
		width: 110px;
		font-size: 16px;
	}
`;

const ButtonStyledInverted = styled(ButtonStyled)`
	background-color: #fff;
	color: #9C73C1;
`;

const PermissionWrapper = styled.div`
	height: 100%;
	width: 100%;
	font-family: ${theme.base_bold_font};
	justify-content: flex-start;
	position: relative;
	background: #6A4E82;
	color: #fff;
	padding: 15px 20px 0;
`;

const Description = styled.p`
	text-align: center;
	font-size: 15px;
	font-weight: bold;
	line-height: 20px;
	margin-bottom: 60px;
	font-family: "The Sun";
`;

const SubDesc = styled.p`
	opacity: 0.7;
	color: #FFFFFF;
	font-family: "The Sun";
	font-size: 10px;
	line-height: 1.2;
	margin-bottom: 37px;
	text-align: center;
	@media (max-width: ${media.mobile}) {
		text-align: left;
	}
`;

const Buttons = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 280px;
	margin: 0 auto;
	
	@media (max-width: 321px) {
		width: 100%;
	}
`;

const ModalWrapperStyled = styled(ModalWrapper)`
	background: #fff;
	padding: 0;
	width: 100%;
	box-sizing: border-box;
`;
interface IState {
	is_form_sending: boolean
}
class PermissionComponent extends Component<IOwn, IState> {
	public state = {
		is_form_sending: false
	};
	constructor(props: IOwn) {
		super(props);

		bindAll(this, [
			'declinePermissions',
			'acceptPermissions',
			'senAnalytics'
		])
	}


	componentDidMount(): void {
		this.senAnalytics();
	}

	public senAnalytics() {
		utagTrack({
			event_action: "competition entry confirmation & marketing permission form",
			question_type: this.props.question.question_type
		}, 'yci');
	}

	public render(): React.ReactNode {

		const { is_open } = this.props;

		if (!is_open) {
			return null;
		}
		return (
			<ModalWrapperStyled is_open={is_open}>
				<PermissionWrapper>
					<Title>
						One last thing...
					</Title>

					<Description>
						Want daily Love Island news sent direct to your inbox?
						<br/>
						Sign up for our TV & Showbiz newsletter full of exclusive news, red hot gossip, interviews and more.
					</Description>

					<SubDesc>
						This info may be used to enhance your experience & will be used in accordance with our <a href="https://newsprivacy.co.uk/single/" target="_blank" rel="noopener noreferrer">
							Privacy Policy.
						</a>
					</SubDesc>
					<Buttons>
						<ButtonStyledInverted onClick={this.declinePermissions}>
							No, thanks
						</ButtonStyledInverted>
						<ButtonStyled onClick={this.acceptPermissions}>
							Yes, please
						</ButtonStyled>
					</Buttons>
				</PermissionWrapper>
			</ModalWrapperStyled>
		)
	}

	private declinePermissions() {
		if (!this.state.is_form_sending){
			this.props.submitPermission({
				permission: 0
			});
		}
		this.setState({
			is_form_sending: true
		});
	}

	private acceptPermissions() {
		if (!this.state.is_form_sending){
			this.props.submitPermission({
				permission: 1
			});
		}

		this.setState({
			is_form_sending: true
		});
	}
}

interface IMap {
	question: IQuestionReducerState,
}
const mapStateToProps = (state: IStoreState): IMap => ({
	question: getQuestion(state),
});

const mapDispatchToProps: IDispatchToProps = ({
	submitPermission
});

export const Permission = connect(mapStateToProps, mapDispatchToProps)(PermissionComponent);

export default Permission;