import styled, { css } from 'styled-components';
import media from  '../../assets/css/media';

export const ContentWrapper = styled.div<{ is_polling?: boolean }>`
	padding: 15px 0 0;
	border-top: none;
	display: flex;
	flex-direction: column;
	
	opacity: 0;
	transition: opacity 300ms ease-in;
	&.fade-in {
		opacity: 1;
	}
	&.fade-out {
		opacity: 0;
	}
	${({ is_polling }) => is_polling ? css`
		height: 400px;
		min-height: 400px;
		max-height: 400px;
		@media (max-width: ${media.height_break_point}) {
			height: 430px;
			min-height: 430px;
			max-height: 430px;
		}
	` : css`
		height: 390px;
		min-height: 390px;
		max-height: 390px;
	`}
`;

export default ContentWrapper;