import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchQuestion, fetchQuestionSuccess, removeAnswer, fetchCompetitionQuestion, setRequiredScreenToShow } from '../../modules/actions';
import bindAll from "lodash/bindAll";
import isString from 'lodash/isString';
import assign from 'lodash/assign';
import { utagTrack } from "../../modules/utils/analytics";
import { IStoreState } from '../../modules/types';
import { LoadingSpinner } from '../AppLoading';
import { getQuestion } from '../../modules/selectors';
import { IQuestionReducerState } from '../../modules/reducers/question';
import { removeAnswerFromStorage } from '../../modules/utils/question';


interface IProps {
	fetchQuestion: typeof fetchQuestion;
	fetchQuestionSuccess: typeof fetchQuestionSuccess;
	fetchCompetitionQuestion: typeof fetchCompetitionQuestion;
	removeAnswer: typeof removeAnswer;
	setRequiredScreenToShow: typeof setRequiredScreenToShow;
	is_loaded?: boolean;
}

interface IOwn extends IProps {
	question: IQuestionReducerState;
}

interface IPostMessage {
	data: string,
	origin: string
}

interface IState {
	game: string;
}

class PostMessageListenerComponent extends Component<IOwn, IState> {
	public state = {
		game: ''
	};
	constructor(props: IOwn, state: IState) {
		super(props, state);

		bindAll(this, [
			'receiveMessage',
		]);
	}
	public componentDidMount() {
		window.addEventListener('message', this.receiveMessage);
	}

	public componentDidUpdate(prevProps: Readonly<IOwn>): void {

		if (!prevProps.question.id && this.props.question.id) {
			const game_type = {
				'yci': 'predictor',
				'polling': 'poll',
				'competition': 'competition'
			}[this.state.game];

			utagTrack({
				event_action: `widget loaded : island ${game_type}`,
				question_type: this.props.question.question_type
			}, this.state.game);
		}
	}

	public parseData(data: string) {
		try {
			return JSON.parse(data) || {}
		} catch ( e ) {
			console.log(data);
			console.error(e);
			return {};
		}
	}

	public receiveMessage(event: IPostMessage) {
		if(event.data && isString(event.data) && event.data.includes('"type"')) {
			const { payload = {}, type } = this.parseData(event.data);
			if  (payload.game && payload.game === 'competition') {
				const id = parseInt(payload.question_id, 10);
				const {screen_to_show} = payload;
				if (screen_to_show) {
					this.props.setRequiredScreenToShow({ required_screen_to_show: screen_to_show });
				}

				this.props.fetchCompetitionQuestion(
					{
						id,
						game: payload.game
					}
				);

				this.setState({
					game: payload.game
				});

				assign(window, {
					data_widget_id: payload.widget_id,
					article_info: payload.analytics
				});

				return;
			}

			if (payload.question_id && payload.question_id !== '0') {
				const id = parseInt(payload.question_id, 10);
				this.props.fetchQuestion(
					{
						id,
						game: payload.game
					}
				);

				this.setState({
					game: payload.game
				});

				assign(window, {
					data_widget_id: payload.widget_id,
					article_info: payload.analytics
				});
			}

			if (type && type === 'voting_widget_clear') {

				const id = parseInt(payload.id, 10);
				this.props.removeAnswer({
					id
				});
				removeAnswerFromStorage(id);
			}

			if (type && type === 'voting_widget_create') {

				const id = parseInt(payload.question_id, 10);
				this.props.fetchQuestionSuccess(
					{
						...payload,
						question_id: id,
					}
				);

				this.setState({
					game: payload.game
				});
			}
		}

	}

	/**
	 * Just return children. HOC
	 */
	public render(): React.ReactNode {
		const { children, is_loaded } = this.props;

		if (!is_loaded) {
			return (
				<LoadingSpinner with_cover_bg={true} position="center"/>
			);
		}

		return children;
	}
}
interface IMap {
	is_loaded: boolean;
	question: IQuestionReducerState;
}
const mapStateToProps = (state: IStoreState): IMap => ({
	is_loaded: state.question.is_loaded,
	question: getQuestion(state),

});
const mapDispatchToProps: IProps = {
	fetchQuestion: fetchQuestion,
	fetchQuestionSuccess: fetchQuestionSuccess,
	fetchCompetitionQuestion,
	removeAnswer,
	setRequiredScreenToShow
};

export const PostMessageListener = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PostMessageListenerComponent);
export default PostMessageListener;