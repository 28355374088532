import { css } from 'styled-components';
import theme from './theme';

export default css`
	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	center,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	menu,
	nav,
	section {
		display: block;
	}
	
	body {
		line-height: 1;
	}
	
	ol,
	ul {
		list-style: none;
	}
	
	blockquote,
	q {
		quotes: none;
	}
	
	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: '';
		content: none;
	}
	
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	
	body {
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		font-family: ${theme.base_font};
		background: ${theme.body_bg};
	}
	html {
		height: 100%;
		overflow: hidden;
	}
	body.fixed-scroll{
		overflow: hidden;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	
	
	.uppercase {
		text-transform: uppercase;
	}
	
	a {
		color: inherit;
		text-decoration: underline;
	}
	
	* {
		box-sizing: border-box;
	}
	
	#root {
		height: 100%;
	}
`;