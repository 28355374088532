import styled from 'styled-components';
import { media } from '../../assets/css/media';
import theme from "../../assets/css/theme";
import RegularButton from './RegularButton';

export const SecondaryButton = styled(RegularButton)`
	background: ${theme.primary};
	border: 1px solid ${theme.primary};
	color: white;

	@media (max-width: ${media.desktop}) {
		margin: 1em auto;
	}
`;

export default SecondaryButton;
