import styled from 'styled-components';
import theme from '../../assets/css/theme';
import media from '../../assets/css/media';

export const QuestionTagLine = styled.div`
	color: ${props => props.theme.text_color};
	font: 28px ${theme.base_bold_font}, sans-serif;
	line-height: 1.2;
	align-items: center;
	margin-bottom: 10px;
	@media (max-width: ${media.height_break_point}) {
		font-size: 24px;
	}
`;

export default QuestionTagLine;