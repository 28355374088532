import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import * as qs from "qs";

export interface IUtagTrackProps {
	event_action: string;
	event_details?: string;
	customer_type?: string;
	question_type?: string;
	question_text?: string;
	answer_text?: string | null;
	newsiq_poe_code?: string;
	page_site_name?: string | null;
	article_id?: string;
	article_url?: string;
	poll_question_channel?: string;
}
const isAMP = () => {
	const url = qs.parse(get(window, 'article_info.url', ''), {
		ignoreQueryPrefix: true,
		decoder: (e: any) => decodeURIComponent(e),
	});

	return url.platform && ~url.platform.indexOf('amp')
};

const pageSiteName = () => {


	const is_amp = isAMP();

	let result = null;

	if(get(window, 'article_info.url', false)) {
		if(~window.article_info.url.indexOf('thesun.co.uk')) {
			result = 'the sun';
		}

		if(~window.article_info.url.indexOf('thescottishsun.co.uk')) {
			result = 'scottish sun';
		}

		if(~window.article_info.url.indexOf('thesun.ie')) {
			result = 'irish sun';
		}

		if(is_amp && result) {
			result += ' amp'
		}
	}

	return result

};
export const utagTrack = (params: IUtagTrackProps, game_type?: string, is_poe_poll?: boolean): void => {
	const utag = window.utag;
	let question_type = '';
	if (game_type) {
		question_type = game_type === 'yci' ? 'island : predictor' : `${params.question_type} : poll`;
	}

	if  (is_poe_poll) {
		question_type = 'news iq';
	}
	const is_amp = isAMP();
	const article_url = get(window, 'article_info.url', '');
	if(utag && isFunction(utag.link)) {
		const full_params: IUtagTrackProps = {
			...params,
			page_site_name: pageSiteName(),
			question_type,
			article_url,
			poll_question_channel: get(params, 'question_type', '')
		};

		if(!is_amp) {
			full_params.article_id = get(window, 'article_info.article_id', null)
		}

		utag.link(full_params);
	}
};