import get from 'lodash/get';
import * as qs from "qs";
import { IShare } from '../../types';

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const share_messages = {
	general: 'I just voted in The Sun\'s Love Island daily poll, make sure you do too! #SunLoveIsland'
};

const isAMP = () => {
	const url = qs.parse(get(window, 'article_info.url', ''), {
		ignoreQueryPrefix: true,
		decoder: (e: any) => decodeURIComponent(e),
	});

	return url.platform && ~url.platform.indexOf('amp')
};

export const share = (data: IShare) => {
	if(data.sc === 'tw'){
		shareTwitter(data);
	}

	if(data.sc === 'fb'){
		shareFB(data);
	}
};

export const shareFB = (data: IShare) => {
	const { sc, league_id, user_id, section } = data;
	const object: any = {
		sc,
		t: getTimestamp()
	};

	if(league_id) {
		object.league_id = league_id;
	}

	if(user_id) {
		object.user_id = user_id;
	}

	const link = isAMP() ? 'https://www.thesun.co.uk/tvandshowbiz/love-island/' : window.article_info.url;

	window.open(
		'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(link) +
		'&quote=' + encodeURIComponent(share_messages[section]),
		'width=626,height=436'
	);
};

export const shareTwitter = (data: IShare) => {
	const { sc, league_id,user_id, section } = data;
	const object: any = {
		sc,
		t: getTimestamp()
	};

	if(league_id) {
		object.league_id = league_id;
	}
	if(user_id) {
		object.user_id = user_id;
	}

	const link = isAMP() ? 'https://www.thesun.co.uk/tvandshowbiz/love-island/' : window.article_info.url;

	window.open(
		'https://twitter.com/share?url=' + encodeURIComponent(link) +
		'&text=' + encodeURIComponent(share_messages[section]),
		'twitter-share-dialog',
		'width=626,height=436'
	);
};

