import styled from 'styled-components';
import media from '../../assets/css/media';

interface IVoteLabel {
	options_length?: number;
	with_image?: boolean;
}

export const VotedLabel = styled.i<IVoteLabel>`
	height: 16px;
	width: 60px;
	color: #393E3F;
	font-family: "The Sun";
	font-size: 10px;
	font-weight: 500;
	line-height: 14px;
	text-align: center;
	background: #fff;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	padding-top: 1px;
	
	@media (max-width: ${media.height_break_point}) {
		position: absolute;
		left: ${({options_length, with_image}) => with_image ? `calc(${(260 / (options_length || 1))}px)` : '0'};
		bottom: ${({options_length = 0}) => options_length > 3 ? '-8px' : 0};
		z-index: 2;
		border-radius: 0 8px 0 0;
		margin-right: 0;
	}
`;

export default VotedLabel;