import { keyframes } from 'styled-components'

export const flip = keyframes`
    from {
        opacity: 0;
        transform: rotateX(-180deg);
    }
    to {
        opacity: 1;
        transform: rotateX(0);
    }
`;

