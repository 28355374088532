import { IQuestionReducerState } from "../reducers/question";
import { IStoreState } from "../types";
import get from 'lodash/get';

export const getAnswer = (state: IStoreState): number =>  state.question.answer;
export const getQuestion = (state: IStoreState): IQuestionReducerState =>  state.question;
export const getEndGameModalState = (state: IStoreState): boolean =>  state.question.is_show_end_game_modal;
export const getDataCaptureFormState = (state: IStoreState): boolean => get(state, 'question.is_show_data_capture_form', false);
export const getPermissionFromState = (state: IStoreState): boolean => get(state, 'question.is_show_permission', false);
export const getCongratsModalState = (state: IStoreState): boolean => get(state, 'question.is_show_congrats', false);
export const getEmail = (state: IStoreState): string =>  get(state, 'question.email', '');
