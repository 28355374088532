import map from 'lodash/map';
import some from 'lodash/some';
import get from 'lodash/get';
import { SagaIterator } from 'redux-saga';
import {
	call,
	put,
	select,
} from 'redux-saga/effects';
import * as actions from '../../actions';
import {
	IQuestionReducerState,
	IQuestionOption,
	IAnswerSelectAction,
	IDataCapture, IPermissions
} from "../../reducers/question";
import { getEmail, getQuestion } from "../../selectors";
import { ISagaAction } from '../../types';
import { utagTrack } from "../../utils/analytics";
import ApiErrors from "../../utils/Api/ApiErrors";
import Api from "../../utils/Api";
import {
	saveQuestionToStorage,
	getAnswerFromStorage,
	saveStatsToStorage,
	getStatsFromStorage
} from "../../utils/question";
import {
	getUserKey
} from "../../utils/user";
import {
	IFetchQuestionPayload
} from '../../reducers';

const countCommonStats = (stats: object) => {
	let result = 0;

	if(stats) {
		const keys = Object.keys(stats);

		for(const item of keys) {
			result += stats[item];
		}
	}


	return result;
};

const getStatsWithStorage = (stats: object, id: number) => {
	const storage = getStatsFromStorage(id);
	if(storage) {
		const storage_count = countCommonStats(storage);
		const current_count = countCommonStats(stats);

		if(storage_count <= current_count) {
			saveStatsToStorage(id, stats);

			return stats;
		}

		return storage;
	}

	saveStatsToStorage(id, stats);
	return stats;
};

export const QuestionJSONSaga = function* (action: ISagaAction<IFetchQuestionPayload>): SagaIterator {

	try {
		const { id, game, is_poe_poll = false } = action.payload;
		const result = yield call(Api.JSON.question, { id });
		const question: IQuestionReducerState = yield select(getQuestion);

		const initial_poll_id = is_poe_poll ? question.id : id;

		if (result.error) {
			throw Error(result.error);
		}

		const answer = result.dont_keep_answer_in_storage ? 0 : getAnswerFromStorage(result.id);

		const stats = getStatsWithStorage(result.stats[game], result.id);
		const { linked_question_id } = result;

		const initial_question = id === initial_poll_id ? result : question;

		yield put(actions.fetchQuestionSuccess({
			...result,
			answer,
			stats: {
				[game]: stats
			},
			at_least_one_image: some(result.options, (option: IQuestionOption) => Boolean(option.image)),
			linked_question_id,
			is_poe_poll,
			initial_poll_id
		}));
		const { final_page_copy = '', final_page_button = '', final_page_button_link = '', color = '' } = initial_question;
		yield put(actions.setInitialQuestionFinal({
			final_page_copy,
			final_page_button,
			final_page_button_link,
			color
		}));

		yield call(calculateStats, { game });
		utagTrack({
			event_action: 'question displayed',
			question_text: result.title,
			question_type: result.question_type
		}, game, is_poe_poll);

		if(answer || result.locked) {
			yield call(ShowStatsSaga, game, is_poe_poll);
		}

	} catch (evt) {
		yield put(actions.fetchQuestionFailed());
		yield put(actions.errorsGlobalError(evt));
	}
};

export const FetchCompetitionQuestion = function* (action: ISagaAction<IFetchQuestionPayload>): SagaIterator {

	try {
		const { id, game, is_poe_poll = false } = action.payload;
		const result = yield call(Api.JSON.question, { id });
		const question: IQuestionReducerState = yield select(getQuestion);

		const initial_poll_id = is_poe_poll ? question.id : id;

		if (result.error) {
			throw Error(result.error);
		}

		const answer = result.dont_keep_answer_in_storage ? 0 : getAnswerFromStorage(result.id);

		const stats = getStatsWithStorage(result.stats[game], result.id);
		const { linked_question_id } = result;

		// const initial_question = id === initial_poll_id ? result : question;
		const is_splash_screen_enabled = get(result, 'splash_page.enabled', 0);

		if (is_splash_screen_enabled) {
			yield put(actions.setScreenToShow({screen_to_show: 'splash'}));
		}

		yield put(actions.fetchQuestionSuccess({
			...result,
			answer,
			stats: {
				[game]: stats
			},
			at_least_one_image: some(result.options, (option: IQuestionOption) => Boolean(option.image)),
			linked_question_id,
			is_poe_poll,
			initial_poll_id
		}));
		// const { final_page_copy = '', final_page_button = '', final_page_button_link = '', color = '' } = initial_question;
		// yield put(actions.setInitialQuestionFinal({
		// 	final_page_copy,
		// 	final_page_button,
		// 	final_page_button_link,
		// 	color
		// }));

		yield call(calculateStats, { game });
		utagTrack({
			event_action: 'question displayed',
			question_text: result.title,
			question_type: result.question_type
		}, game, is_poe_poll);

		// if(answer || result.locked) {
		// 	yield call(ShowStatsSaga, game, is_poe_poll);
		// }

	} catch (evt) {
		yield put(actions.fetchQuestionFailed());
		yield put(actions.errorsGlobalError(evt));
	}
};

export const SelectAnswerSaga = function* (action: ISagaAction<IAnswerSelectAction>): SagaIterator {
	try {


		const question: IQuestionReducerState = yield select(getQuestion);
		const {
			stats, title, id, dont_keep_answer_in_storage, options, linked_question_id, is_poe_poll, question_type
		} = question;
		const answer_id = action.payload.id;

		const { errors } = yield call(Api.Polling.save, {
			question_id: id,
			option_id: answer_id,
			user_key: getUserKey()
		});

		ApiErrors.checkOnApiError({ errors });

		if(!dont_keep_answer_in_storage && id) {
			saveQuestionToStorage(id, answer_id);
		}

		const new_stats = {
			...stats,
			polling: {
				...stats.polling,
				[`${action.payload.id}`]: get(stats, `polling[${action.payload.id}]`, 0) + 1
			}
		};

		const stats_store = getStatsWithStorage(new_stats.polling, id);
		yield put(actions.selectAnswerSuccess({
			answer: action.payload.id,
			stats: {
				polling: stats_store
			}

		}));

		const option = options.find(o => o.id === answer_id);

		utagTrack({
			event_action: 'question answered',
			question_type,
			question_text: title,
			answer_text:  get(option, 'title', null),
			newsiq_poe_code: get(option, 'tealium_id', ''),
		}, 'polling', is_poe_poll);

		yield call(calculateStats, {game: 'polling'});
		yield call(ShowStatsSaga, 'polling', is_poe_poll);
		if (linked_question_id || is_poe_poll) {
			yield put(actions.showTimerToFetchPOEPoll());
		}
		else {
			yield put(actions.showTimerToFetchPOEPoll());
		}

	} catch ( e ) {
		yield put(actions.errorsGlobalError(e));

	}
};

export const PostYCIAnswerSaga = function* (action: ISagaAction<IAnswerSelectAction>): SagaIterator {
	try {


		const question: IQuestionReducerState = yield select(getQuestion);
		const { question_type, title, id, dont_keep_answer_in_storage, options } = question;
		const answer_id = action.payload.id;

		const { result, errors } = yield call(Api.YCI.save, {
			question_id: id,
			options: [answer_id],
			user_key: getUserKey()
		});

		ApiErrors.checkOnApiError({ errors });

		if(!dont_keep_answer_in_storage && id) {
			saveQuestionToStorage(id, answer_id);
		}
		const { stats } = result;
		const new_stats = {
			...stats,
			yci: {
				...stats.yci,
				[`${action.payload.id}`]: get(stats, `yci[${action.payload.id}]`, 0) + 1
			}
		};
		const stats_store = getStatsWithStorage(new_stats.yci, id);

		yield put(actions.postYCIAnswerSuccess({
			stats: {
				yci: stats_store
			},
			answer: action.payload.id
		}));

		const option = options.find(o => o.id === answer_id);

		yield call(calculateStats, {game: 'yci'});
		yield put(actions.showThankYouModal());

		utagTrack({
			event_action: 'question answered',
			question_type,
			question_text: title,
			answer_text:  get(option, 'title', null)
		}, 'yci');
	} catch ( e ) {
		yield put(actions.errorsGlobalError(e));

	}
};

export const PostCompetitionAnswerSaga = function* (action: ISagaAction<IAnswerSelectAction>): SagaIterator {
	try {
		const question: IQuestionReducerState = yield select(getQuestion);
		const { question_type, title, id, dont_keep_answer_in_storage, options } = question;
		const answer_id = action.payload.id;
		const { result, errors } = yield call(Api.Competition.save, {
			question_id: id,
			option_id: answer_id,
			user_key: getUserKey()
		});

		ApiErrors.checkOnApiError({ errors });

		if(!dont_keep_answer_in_storage && id) {
			saveQuestionToStorage(id, answer_id);
		}
		const { stats } = result;
		const new_stats = {
			...stats,
			competition: {
				...stats.competition,
				[`${action.payload.id}`]: get(stats, `competition[${action.payload.id}]`, 0) + 1
			}
		};
		const stats_store = getStatsWithStorage(new_stats.competition, id);

		yield put(actions.PostCompetitionAnswerSuccess({
			stats: {
				competition: stats_store
			},
			answer: action.payload.id
		}));

		const option = options.find(o => o.id === answer_id);

		yield call(calculateStats, {game: 'competition'});
		yield put(actions.setScreenToShow({screen_to_show: 'form_capture'}));

		utagTrack({
			event_action: 'question answered',
			question_type,
			question_text: title,
			answer_text:  get(option, 'title', null)
		}, 'competition');
	} catch ( e ) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));

	}
};

export const calculateStats = function* ({ game }: { game: string }) {
	const question: IQuestionReducerState = yield select(getQuestion);

	const stats = question.stats[game];

	const HUNDRED = 100;

	if(stats) {
		const common_counts = countCommonStats(stats);

		const opts = map(question.options, (option: any) => {

			let percentage = 0;
			if(common_counts) {
				const stat = stats[option.id] || 0;
				percentage = (stat / common_counts  * HUNDRED);
			}

			return {
				...option,
				percentage: percentage.toFixed(1)
			}

		});

		yield put(actions.calculateStatsSuccess(opts));
	}


};


export const ShowStatsSaga = function* (game: string, is_poe_poll?: boolean): SagaIterator {
	const { title, question_type }: Partial<IQuestionReducerState> = yield select(getQuestion);

	utagTrack({
		event_action: "question stats",
		question_type,
		question_text: title
	}, game, is_poe_poll);

	yield put(actions.showStats());
};

export const SubmitDataCaptureSaga = function* (action: ISagaAction<IDataCapture>): SagaIterator {
	try {


		const question: IQuestionReducerState = yield select(getQuestion);
		const { id } = question;
		const {
			first_name,
			last_name,
			email,
			gender,
			age,
			marketing_opt_in,
			marketing_other
		} = action.payload;
		const user_key = getUserKey();

		const { /*result,*/ errors } = yield call(Api.Competition.data_capture, {
			question_id: id,
			user_key,
			first_name,
			last_name,
			email,
			gender,
			age,
			marketing_opt_in,
			marketing_other
		});

		ApiErrors.checkOnApiError({ errors });

		// const { permission } = result;
		yield put(actions.setScreenToShow({screen_to_show: 'confirmation'}));
		// yield put(actions.submitDataCaptureSuccess({
		// 	permission,
		// 	email
		// }));

	} catch ( e ) {
		yield put(actions.errorsGlobalError(e));

	}
};
export const SubmitPermissionSaga = function* (action: ISagaAction<IPermissions>): SagaIterator {
	try {
		const {
			permission
		} = action.payload;
		const user_key = getUserKey();
		const email: string = yield select(getEmail);

		const { errors } = yield call(Api.YCI.permission, {
			email,
			user_key,
			permission
		});

		ApiErrors.checkOnApiError({ errors });


		yield put(actions.submitPermissionSuccess());

		const question: IQuestionReducerState = yield select(getQuestion);

		utagTrack({
			event_action: 'marketing permission form submitted',
			answer_text: permission ? 'yes please' : 'no thanks',
			question_type: question.question_type
		}, 'yci');
	} catch ( e ) {
		yield put(actions.errorsGlobalError(e));

	}
};