import { createAction } from "redux-act";
import {
	IQuestionReducerState,
	IAnswerSelect,
	IAnswerSelectAction,
	IDataCapture,
	IPermissions,
	IQuestionFinal,
	IFetchQuestionPayload,
} from '../reducers';

export const fetchQuestion = createAction<IFetchQuestionPayload>();
export const fetchQuestionSuccess = createAction<IQuestionReducerState>();
export const fetchQuestionFailed = createAction();

export const fetchCompetitionQuestion = createAction<IFetchQuestionPayload>();

export const selectAnswer = createAction<IAnswerSelectAction>();
export const selectAnswerSuccess = createAction<IAnswerSelect>();

export const postYCIAnswer = createAction<IAnswerSelectAction>('Post YCI Answer');
export const postYCIAnswerSuccess = createAction<IAnswerSelect>('Post YCI Answer Success');

export const PostCompetitionAnswer = createAction<IAnswerSelectAction>();
export const PostCompetitionAnswerSuccess = createAction<IAnswerSelect>();

export const showThankYouModal = createAction();
export const showStats = createAction();

export const calculateStatsSuccess = createAction<IQuestionReducerState["options"]>();
export const progressRingSuccess = createAction('Progress Ring Filled');
export const triggerDataCapture = createAction('Show Data Capture Form');
export const submitDataCapture = createAction<IDataCapture>('Post Data Capture');
export const submitDataCaptureSuccess = createAction<IPermissions>('Post Data Capture Success');
export const submitPermission = createAction<IPermissions>('Post Permissions');
export const submitPermissionSuccess = createAction('Post Permissions Success');

export const showTimerToFetchPOEPoll = createAction('Show Timer To Fetch POE Poll');
export const showFinal = createAction('Show Final');
export const setInitialQuestionFinal = createAction<IQuestionFinal>();

export const removeAnswer = createAction<IAnswerSelectAction>();