import styled from 'styled-components';
import theme from '../../assets/css/theme';

export const QuestionHeader = styled.h2`
	color: #fff;
	text-align: center;
	padding: 0 5px;
	font-family: ${theme.secondary_bold_font};
	font-weight: bold;
	border: 1px solid ${props => props.theme.color};
	background: ${props => props.theme.color};
	margin-right: 5px;
	display: inline-block;
	text-transform: uppercase;
	font-size: 24px;
`;

export default QuestionHeader;