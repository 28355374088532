import * as React from 'react';
import styled from 'styled-components';
import checked_icon from '../../assets/img/icons/checkbox-tick.png';
import uniqueId from 'lodash/uniqueId';
import media from '../../assets/css/media';
import theme from '../../assets/css/theme';

interface ICheckbox extends React.AllHTMLAttributes<HTMLInputElement> {
	// readonly onChange: (e: React.SyntheticEvent<EventTarget>) => void;
	readonly children: React.ReactNode;
	readonly name: string;
	readonly id?: string;
	readonly tick_position?: string;
	readonly type?: string;
	readonly as?: any;
	readonly is_radio?: boolean;
	readonly width?: string;
}

const StyledCheckbox = styled.div`
	margin: 10px auto;
	width: 100%;
	overflow: hidden;
	position: relative;
	padding: 1px 0;
	min-height: 26px;
	a {
		color: #6E7070;
	}
	
	@media (max-width: ${media.mobile}) {
		margin: 5px auto;
	}
`;

interface ICheckboxStyled {
	readonly tick_position?: string;
}

const CheckboxStyled = styled.input`
	position: absolute;
	${({ tick_position }: ICheckboxStyled) => (tick_position)}: 0;
	z-index: 0;
	visibility: hidden;
`;

interface ILabel extends ICheckboxStyled {
	readonly is_radio?: boolean;
	readonly width?: string;
}

const Label = styled.label`
	transition: .4s;
	min-height: 26px;
	padding-${({ tick_position }: ILabel) => (tick_position)}: 30px;
	position: relative;
	text-align: left;
	max-width: ${({ width }: ILabel) => (width || '100%')};
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	color: #222526;
	font-family: "The Sun";
	font-size: 12px;
	line-height: 16px;
	${({ is_radio }: ILabel) => (is_radio ? `
		display: inline-flex;
		align-items: center;
	`: '')}
	@media (max-width: ${media.mobile}) {
		font-size: 10px;
		align-items: flex-start;
	}
	a {
		color: #222526;
		text-decoration: underline;
		font-family: ${theme.base_bold_font};
	}
	&:before {
		position: absolute;
		${({ tick_position }: ILabel) => (tick_position)}: 0;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		content: '';
		border: 2px solid #222526;
		width: 24px;
		height: 24px;
		box-sizing: border-box;
		background-color: white;
		z-index: 1;
		${CheckboxStyled}:checked + & {
			background-image: url(${checked_icon});
			background-size: 1.5em;
			background-repeat: no-repeat;
			background-position: center;
		}
		@media (max-width: ${media.mobile}) {
			top: 2px;
			transform: translateY(0);
		}
	} 
`;

export const Checkbox = ({
  onChange,
  children,
  tick_position = 'left',
  type = 'checkbox',
  id = uniqueId('checkbox'),
  width,
  ...rest
}: ICheckbox) => (
	<StyledCheckbox>
		<CheckboxStyled
			onChange={onChange}
			id={id}
			type={type}
			tick_position={tick_position}
			{...rest}
		/>
		<Label
			htmlFor={id}
			is_radio={type === 'radio'}
			tick_position={tick_position}
			width={width}
		>
			{children}
		</Label>
	</StyledCheckbox>
);

export default Checkbox;
