import styled from 'styled-components';

export const IconWrap = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: -11px;
	z-index: 2;
	background-color: #FFFFFF;
	width: 22px;
	height: 22px;
	font-size: 18px;
	color: ${props => props.theme.color};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	
	.user-answer {
		width: 14px;
	}
	
	.user-answer-wrong {
		width: 100%;
	}
`;

export default IconWrap;