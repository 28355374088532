import * as React from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown-now';
import isNaN from 'lodash/isNaN';

interface ICountdownWrapper {
	is_uppercase?: boolean;
}

const CountdownTimerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
`;

const Unit = styled.div`
	font-size: 18px;
	line-height: 1;
	text-align: center;
	color: #fff;
	font-family: 'The Sun Heavy Narrow', sans-serif;
	width: 23px;
`;

const UnitDivider = styled(Unit)`
	font-size: 16px;
	width: 5px;
`;

const Label = styled.div`
	color: #fff;
	font-family: "The Sun", sans-serif;
	font-size: 8px;
	line-height: 2;
	text-align: center;
	text-transform: ${({ is_uppercase }: ICountdownWrapper) => is_uppercase ? 'uppercase' : 'none'};
	width: 25px;
`;

const LabelsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const UnitsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const checkForMultiplyDateTimeLabel = (unit: string) => parseInt(unit, 10) === 1 ? '' : 's';

interface IProps {
	date: string;
	onComplete?: () => any;
	label_type?: string;
	is_uppercase?: boolean;
	with_label?: boolean;
	className?: string;
	labelStyle?: any
}

const getWithZeroPrefix = (unit: string) => {
	const int_unit = parseInt(unit, 10);
	return int_unit < 10 ? `0${int_unit}` : int_unit;
};

const getMinutesLabel = (label: any, time: any, labelStyle: any) => {
	return (
		<React.Fragment>
			<Label
				as={labelStyle || 'div'}
				is_uppercase={label}
			>
				{label}{checkForMultiplyDateTimeLabel(time)}
			</Label>
		</React.Fragment>
	);
};

const getMinutes = (time: any, day: any) => {
	return (
		<React.Fragment>
			<Unit>
				{getWithZeroPrefix(time)}
			</Unit>
			{!day && (<UnitDivider> : </UnitDivider>)}
		</React.Fragment>
	);
};

const getLabels = ({days, label, is_uppercase, hours, minutes, seconds, labelStyle }: any ) => {
	return (
		<LabelsWrapper>
			{days ? (
				<React.Fragment>
					<Label
						as={labelStyle || 'div'}
						is_uppercase={is_uppercase}
					>
						{label.days}{checkForMultiplyDateTimeLabel(days)}
					</Label>
				</React.Fragment>
			) : null}
			<Label
				as={labelStyle || 'div'}
				is_uppercase={is_uppercase}
			>
				{label.hours}{checkForMultiplyDateTimeLabel(hours)}
			</Label>
			{getMinutesLabel(label.minutes, minutes, labelStyle)}
			{!days ? (
				<React.Fragment>
					<Label
						as={labelStyle || 'div'}
						is_uppercase={is_uppercase}
					>
						{label.seconds}{checkForMultiplyDateTimeLabel(seconds)}
					</Label>
				</React.Fragment>
			) : null}
		</LabelsWrapper>
	)
};

const renderer = (
	{ days, hours, minutes, seconds, completed, label_type, is_uppercase, with_label, labelStyle , ...props } : any
) => {
	const label = {
		full: {
			days: 'Day',
			hours: 'Hour',
			minutes: 'Minute',
			seconds: 'Second'
		},
		short: {
			days: 'Day',
			hours: 'Hr',
			minutes: 'Min',
			seconds: 'Sec'
		}
	}[label_type || 'short'];

	// Render a countdown
	return (
		<CountdownTimerWrapper className={props.className}>

			<UnitsWrapper>
				{days ? (
					<React.Fragment>
						<Unit>
							{completed ? '00' : getWithZeroPrefix(days)}
						</Unit>
						<UnitDivider> : </UnitDivider>
					</React.Fragment>
				) : null}
				<Unit>
					{getWithZeroPrefix(hours)}
				</Unit>
				<UnitDivider> : </UnitDivider>

				{getMinutes(minutes, days)}
				{!days ? (
					<Unit>
						{getWithZeroPrefix(seconds)}
					</Unit>
				) : null}
			</UnitsWrapper>

			{with_label ? (
				getLabels({ days, hours, minutes, seconds,label, is_uppercase, labelStyle })
			): null}

		</CountdownTimerWrapper>
	);
};

const render = (label_type: any, is_uppercase: any, className: any, with_label: boolean, labelStyle: any, { ...props }) => {
	return renderer({ label_type, is_uppercase, className, with_label,labelStyle, ...props });
};

export const CountdownTimer = (
	{ date, onComplete, label_type, is_uppercase, with_label = true, className, labelStyle }: IProps
) => {
	const to_date = new Date(date);
	return !isNaN(to_date.getTime()) ? (
		<Countdown
			date={to_date}
			onComplete={onComplete}
			renderer={render.bind(null, label_type, is_uppercase, className, with_label, labelStyle)}
		/>
	) : null;
};

export default CountdownTimer;