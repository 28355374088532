import styled from 'styled-components';
import { easeIn } from '../keyFrames';

export const QuestionWrapper = styled.div`
	background: #fff;
	animation: ${easeIn} 0.3s ease;
	position: relative;
	height: ${props => props.theme.height};
	min-height: ${props => props.theme.height};
	max-height: ${props => props.theme.height};
	box-sizing: border-box;
	margin: 20px 0;
	border-top: 1px solid #ADAFB0;
	border-bottom: 1px solid #ADAFB0;

	*::selection {
		color: #fff;
		background:${props => props.theme.text_color};
	}
`;

export default QuestionWrapper;