import * as React from 'react';
import styled from 'styled-components';
import { progressRingSuccess } from '../../modules/actions';
import { connect } from 'react-redux';

const Circle = styled.svg`
	overflow: visible;
	z-index: 2;
	position: relative;
	circle {
	  transition: stroke-dashoffset 75ms;
	  transform: rotate(-90deg);
	  transform-origin: 50% 50%;
	}

`;

interface IProps {
	progressRingSuccess: typeof progressRingSuccess;
	percentage: number;

}

type State = {
	progress: number
}

class ProgressRingComponent extends React.Component<IProps, State> {
	public state = {
		progress: 0
	};

	private sTimeout: any;

	componentWillUnmount(): void {
		clearInterval(this.sTimeout);
	}

	public render() {
		const props = {
			radius: 70,
			stroke: 20,
			progress: 23
		};
		const { radius, stroke } = props;
		const { progress } = this.state;

		const normalized_radius = radius - 3 * 2;
		const circumference = normalized_radius * 2 * Math.PI;
		const strokeDashoffset = circumference - progress / 100 * circumference;

		return (
			<Circle
				height={radius * 2}
				width={radius * 2}
			>
				<circle
					stroke="#9C73C1"
					fill="transparent"
					strokeWidth={ stroke }
					strokeDasharray={ circumference + ' ' + circumference }
					style={ { strokeDashoffset } }
					r={ normalized_radius }
					cx={ radius }
					cy={ radius }
				/>
			</Circle>
		);
	}

	public componentDidMount() {
		const { percentage } = this.props;
		// emulating progress
		this.sTimeout = setInterval(() => {
			this.setState({ progress: this.state.progress + 1 });
			if (this.state.progress === percentage) {
				clearInterval(this.sTimeout);
				this.props.progressRingSuccess();
			}

		}, 40);
	}
}

const mapDispatchToProps = {
	progressRingSuccess
};

export const ProgressRing = connect(null, mapDispatchToProps)(ProgressRingComponent);

export default ProgressRing;
