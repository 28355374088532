import React from 'react';
import styled, { css } from "styled-components";
import theme from "../../assets/css/theme";
import { IStoreState } from '../../modules/types';
import { getQuestion } from '../../modules/selectors';
import { IQuestionReducerState } from '../../modules/reducers/question';
import { connect } from 'react-redux';

interface IAlign {
	align?: string
}

interface IQuestionFooter extends IAlign {
	is_show_stats?: boolean;
	game: string;
	is_poe_poll?: boolean;
	question: IQuestionReducerState;
}
const FooterWrapper = styled.footer<IAlign>`
	//padding-bottom: 15px;
	//height: 60px;
	justify-content: center;
	font-size: 10px;
	line-height: 16px;
	color: #222526;
	margin: auto 0;
	font-family: ${theme.base_font};
	a {
		color: #6e7070;
	}
	
	p {
		margin-bottom: 5px;
	}
	${({ align }) => align === 'center' ? css`
		align-items: center;
		padding-top: 15px;
` : ''}
`;

const PostFooterWrapper = styled(FooterWrapper)`
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	line-height: 19px;
	a {
		font-weight: 400;
		color: ${props => props.theme.color};
	}
`;

const InitialFooter = ({ align = 'left' }: IAlign) => (
	<FooterWrapper align={align}>
		<span>
			This info may be used to enhance your experience & will be used in accordance with our <a
			href="https://newsprivacy.co.uk/single/" target="_blank" rel="noopener noreferrer">Privacy Policy.</a>
		</span>
	</FooterWrapper>
);

const PostFooter = ({ game, is_poe_poll }: IQuestionFooter) => {
	let text = `Thanks for ${game === 'yci' ? 'playing!' : 'voting!'}`;
	if (is_poe_poll)  {
		text = "You've already voted - thanks!"
	}
	return (
		<PostFooterWrapper>
			<p><strong>{text}</strong></p>
		</PostFooterWrapper>
	)
};

class QuestionFooterComponent extends React.Component<IQuestionFooter> {

	public render() {
		const {is_show_stats, game, is_poe_poll, question} = this.props;

		return is_show_stats ? (
			<PostFooter game={game} is_poe_poll={is_poe_poll} question={question} />
		) : <InitialFooter />;
	}
}

interface IMap {
	question: IQuestionReducerState,
}
const mapStateToProps = (state: IStoreState): IMap => ({
	question: getQuestion(state),
});
export const QuestionFooter = connect(mapStateToProps)(QuestionFooterComponent);
export default QuestionFooter;
