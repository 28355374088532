
interface IMediaPoints {
	desktop: string,
	mobile: string,
	height_break_point: string,
	small_mobile: string;
}
export const media: IMediaPoints = {
	desktop: '960px',
	mobile: '480px',
	small_mobile: '330px',
	height_break_point: '619px'
};

export default media
