import { Component } from 'react';

interface IState {
	hasError: boolean
}
class ErrorBoundaryComponent extends Component<any, IState> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	public static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	/**
	 * Catch some errors when it renders
	 * @param error
	 * @param info
	 */
	public componentDidCatch(error: any, info: any) {
		// You can also log the error to an error reporting service
		console.log(error, info);
	}

	/**
	 * HOC
	 */
	public render() {
		if (this.state.hasError) {
			// fallback UI
			return null;
		}

		return this.props.children;
	}
}

export const ErrorBoundary = ErrorBoundaryComponent;