import { createReducer } from 'redux-act';
import * as actions from '../../actions';

export interface IFetchQuestionPayload { id: number, game: string, is_poe_poll?: boolean }

export interface IAnswerSelectAction {
	readonly id: number,
}
export interface IDataCapture {
	readonly first_name: string,
	readonly last_name: string,
	readonly email: string,
	readonly gender: string,
	readonly age: string;
	readonly marketing_opt_in: string;
	readonly marketing_other: string;
}
export interface IPermissions {
	readonly permission: number | null
	readonly email?: string
}

export interface IAnswerSelect {
	readonly answer?: number,
	readonly stats?: IQuestionStats
}

export interface IQuestionOption extends IAnswerSelectAction {
	readonly title: string,
	readonly image: string,
	readonly tealium_id: string
	readonly percentage: number
	readonly correct: number
}

export interface IQuestionStats {
	polling?: object,
	yci?: object,
	competition?: object;
}

interface IAdditionslProps {
	readonly answer: number;
	readonly dont_keep_answer_in_storage: boolean;
	readonly is_show_end_game_modal: boolean;
	readonly is_show_stats: boolean;
	readonly at_least_one_image: boolean;
	readonly is_data_requested: boolean;
	readonly is_show_data_capture_form: boolean;
	readonly permission: number | null;
	readonly is_show_permission: boolean;
	readonly is_show_congrats: boolean;
	readonly email?: string;
	readonly show_timer_to_fetch_POE_poll: boolean;
	readonly show_final?: boolean;
}
export interface IQuestionFinal {
	readonly final_page_copy?:  string;
	readonly final_page_button?:  string;
	readonly final_page_button_link?:  string;
	readonly color?: string
}

export interface ISplashPage {
	enabled: 0 | 1;
	promo_text: string;
	promo_sub_header: string;
	image: string;
}

export interface IConfirmationPage {
	prize_supplier_info: string;
	prize_supplier_url: string;
	image: string;
}

export interface IQuestionReducerState extends IAdditionslProps {
	readonly id: number,
	readonly title: string,
	readonly color: string,
	readonly header_title: string;
	readonly header_title_mobile: string;
	readonly view: string,
	readonly view_number: number,
	readonly has_answer: boolean,
	readonly options: IQuestionOption[],
	readonly locked: number,
	readonly tealium_id: string,
	readonly stats: IQuestionStats,
	readonly question?: string,
	readonly close_date?: string,
	readonly is_loaded: boolean,
	readonly progress_ring_filled: boolean
	readonly linked_question_id?: number
	readonly is_poe_poll?: boolean
	readonly initial_poll_id?: number;
	readonly footer_copy?: string;
	readonly footer_link?: string;
	readonly final_page_copy?:  string;
	readonly final_page_button?:  string;
	readonly final_page_button_link?:  string;
	readonly initial_question_final: IQuestionFinal;
	readonly question_type: string;
	preview_mode?: number;
	has_images: boolean;
	readonly splash_page: ISplashPage;
	readonly confirmation_form: IConfirmationPage
}

const initialState: IQuestionReducerState = {
	id: 0,
	title: '',
	view: '',
	header_title: '',
	header_title_mobile: '',
	view_number: 0,
	has_answer: false,
	options: [],
	locked: 0,
	stats: {
		polling: {}
	},
	answer: 0,
	dont_keep_answer_in_storage: false,
	is_show_end_game_modal: false,
	is_show_stats: false,
	at_least_one_image: false,
	has_images: false,
	is_data_requested: false,
	is_loaded: false,
	color: 'transparent',
	tealium_id: '',
	progress_ring_filled: false,
	is_show_data_capture_form: false,
	permission: null,
	is_show_permission: false,
	is_show_congrats: false,
	email: '',
	show_timer_to_fetch_POE_poll: false,
	show_final: false,
	is_poe_poll: false,
	initial_question_final: {
		final_page_copy: '',
		final_page_button: '',
		final_page_button_link: '',
		color: ''
	},
	question_type: '',
	splash_page: {
		enabled: 0,
		promo_text: '',
		promo_sub_header: '',
		image: ''
	},
	confirmation_form: {
		prize_supplier_info: '',
		prize_supplier_url: '',
		image: ''
	}
};

export const question = createReducer<IQuestionReducerState>({}, initialState)
	.on(actions.fetchQuestion, (state: IQuestionReducerState) => ({
		...state,
		is_data_requested: true,
		show_timer_to_fetch_POE_poll: false,
		is_show_stats: false
	}))
	.on(actions.fetchQuestionSuccess, (state, payload) => ({
		...state,
		...payload,
		is_data_requested: false,
		is_loaded: true,
		show_final: false
	}))
	.on(actions.setInitialQuestionFinal, (state, payload) => ({
		...state,
		initial_question_final: {
			...payload
		},
	}))
	.on(actions.fetchQuestionFailed, () => initialState)
	.on(actions.selectAnswerSuccess, (state: IQuestionReducerState, payload: IAnswerSelect) => ({
		...state,
		...payload
	}))
	.on(actions.postYCIAnswerSuccess, (state: IQuestionReducerState, payload: IAnswerSelect) => ({
		...state,
		...payload
	}))
	.on(actions.PostCompetitionAnswerSuccess, (state: IQuestionReducerState, payload: IAnswerSelect) => ({
		...state,
		...payload
	}))
	.on(actions.showThankYouModal, (state: IQuestionReducerState) => ({
		...state,
		is_show_end_game_modal: true
	}))
	.on(actions.calculateStatsSuccess, (state: IQuestionReducerState, opt: IQuestionReducerState["options"]) => ({
		...state,
		options: opt
	}))
	.on(actions.showStats, (state: IQuestionReducerState) => ({
		...state,
		is_show_stats: true
	}))
	.on(actions.progressRingSuccess, (state: IQuestionReducerState) => ({
		...state,
		progress_ring_filled: true
	}))
	.on(actions.triggerDataCapture, (state: IQuestionReducerState) => ({
		...state,
		is_show_end_game_modal: false,
		is_show_data_capture_form: true
	}))
	.on(actions.submitDataCaptureSuccess, (state: IQuestionReducerState, payload: IPermissions) => ({
		...state,
		...payload,
		is_show_data_capture_form: false,
		is_show_permission: payload.permission === null,
		is_show_congrats: payload.permission !== null,
		email: payload.permission === null ? payload.email : ''
	}))
	.on(actions.submitPermissionSuccess, (state: IQuestionReducerState) => ({
		...state,
		is_show_permission: false,
		is_show_congrats: true
	}))
	.on(actions.showTimerToFetchPOEPoll, (state: IQuestionReducerState) => ({
		...state,
		show_timer_to_fetch_POE_poll: true
	}))
	.on(actions.showFinal, (state: IQuestionReducerState) => ({
		...state,
		show_timer_to_fetch_POE_poll: false,
		show_final: true
	}))
	.on(actions.removeAnswer, (state: IQuestionReducerState) => ({
		...state,
		show_timer_to_fetch_POE_poll: false,
		is_show_stats: false,
		show_final: false,
		answer: 0
	}))
;