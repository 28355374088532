import styled from "styled-components";


export const ImageWrapper = styled.div`
	height: 100%;
	max-width: 100px;
	width: 100%;
	border: 1px solid #F3F6F7;
	background-color: #F3F6F7;
	border-radius: 2px;
	margin-right: 0.5em;
`;