import { HTTPClient as HTTPClientCore, IRequestConfig } from '@fanhubmedia/fe-common-utils';
import axios from 'axios';
import * as qs from 'qs';
import { CANCEL } from 'redux-saga';
import { Storage } from "../Storage";


class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest(config: IRequestConfig) {
		const source = axios.CancelToken.source();

		const request: any = super.makeRequest({
			...config,
			params     : {
				...config.params,
				_  : Date.now(),
				sid: Storage.get('sid'),
			},
			cancelToken: source.token,
		});

		request[ CANCEL ] = () => source.cancel();

		return request;
	}
}

const APIClient = new HTTPClient({
	baseURL         : process.env.REACT_APP_API_URL || '',
	withCredentials : true,
	transformRequest: [ data => qs.stringify(data) ],
});

const JSONClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_SPORT_URL || '',
});

export const Api = {
	JSON: {
		question: ({id}: { id: number }) => JSONClient.get(`questions/${id}.json`),
	},
	Auth: {},
	User: {
		create: (params: object) => APIClient.post('user/create', params),
	},
	Polling: {
		save: (p: object) => APIClient.post('polling/save', p)
	},
	YCI: {
		save: (p: object) => APIClient.post('yci/save_widget', p),
		data_capture: (d: object) => APIClient.post('yci/data_capture', d),
		permission: (d: object) => APIClient.post('yci/permissions', d)
	},
	Competition: {
		save: (p: object) => APIClient.post('competition/save_widget', p),
		data_capture: (d: object) => APIClient.post('competition/data_capture', d),
		permission: (d: object) => APIClient.post('competition/permissions', d)
	}
};

export * from './ApiErrors';

export default Api;

