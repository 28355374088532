import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from "react-redux";
import styled from "styled-components";

import { ModalWrapper } from "../";
import * as actions from "../../modules/actions";
import { IStoreState } from "../../modules/types";


interface IProps {
	readonly error: string;
	readonly clearError: (payload?: object) => object
}

const MessageBox = styled.div`
	box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
	padding: 1.5em;
	font-size: 1.25em;
	color: #000;
	background-color: #fff;
`;

const GlobalErrorsComponent = ({ error, clearError }: IProps) => (
	<ModalWrapper is_open={!isEmpty(error)} onClick={clearError} >
		<MessageBox dangerouslySetInnerHTML={{__html: error}} />
	</ModalWrapper>
);

const mapStateToProps = (state: IStoreState) => ({
	error: get(state, 'error_managment.text', ''),
});

const mapDispatchToProps = {
	clearError: actions.errorsClearGlobalError
};

export const GlobalErrors = connect(mapStateToProps, mapDispatchToProps)(GlobalErrorsComponent);
export default GlobalErrors;