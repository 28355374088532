import styled from 'styled-components';

export const Loader = styled.div`
	width: 32px;
	height: 32px;
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 10px;
	margin: 0 auto;
	&:before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		border: 2px solid transparent;
		border-top-color: #000;
		animation: load3 1s linear infinite;
	}
	@keyframes load3 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
`;

export default Loader;