const BASE = process.env.REACT_APP_BASE_URL || '';
const VERSION = process.env.REACT_APP_STORAGE_VERSION || '';

/**
 * Wrapper for localStorage that made storage key uniq for any base path.
 * It's required for multiple projects on the same domain but at different folders
 */
export class Storage {

	public static getKey(key: string): string {
		return BASE + key + '-' + VERSION;
	}
	/**
	 * Store key/value data in localStorage
	 */
	public static set(key: string, value: any): void {
		const val = typeof value === 'string' ? value : JSON.stringify(value || '');
		localStorage.setItem(Storage.getKey(key), val);
	}

	/**
	 * Get value by key from localStorage
	 */
	public static get(key: string) {
		return localStorage.getItem(Storage.getKey(key));
	}

	/**
	 * Remove value by key from localStorage
	 */
	public static remove(key: string): void {
		localStorage.removeItem(Storage.getKey(key));
	}

	constructor() {
		throw new Error('This class is static and new instance can\' be instantiated!');
	}
}

export default Storage;