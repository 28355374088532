import { createReducer } from 'redux-act';
import * as actions      from '../../actions';

export interface IError {
	readonly text?: string;
}

const defaultState: IError = {
	text: ''
};

export const error_managment = createReducer<IError>( {}, defaultState );

error_managment.on( actions.errorsShowGlobalError, ( state: IError, payload: IError ) => ({
	...state,
	...payload
}) );

error_managment.on( actions.errorsClearGlobalError, () => ({
	...defaultState
}) );

export default error_managment;
