import styled from 'styled-components';
import theme from "../../assets/css/theme";

export interface IButtonInterface {
	readonly transparent?: boolean
	readonly disabled?: boolean
	readonly margin?: string
	readonly max_width?: string,
	readonly color_bg?: string,
	readonly is_locked_state?: boolean,
	readonly color_border?: string,
	readonly color_text?: string,
}

export const RegularButton = styled.button`
	color: ${({ disabled }: IButtonInterface) => (disabled ? theme.buttons_color : '#fff' )};
	font-family: ${theme.base_bold_font};
	font-size: 1em;
	line-height: 40px;
	font-weight: bold;
	text-align: center;
	width: 100%;
	max-width: ${({ max_width }: IButtonInterface) => (max_width || '100%')};
	margin: ${({ margin }: IButtonInterface) => (margin || 'auto')};
	height: 40px;
	border: 1px solid #fff;
	background: ${({ transparent }: IButtonInterface) => (transparent ? 'transparent' : theme.primary)};
	opacity: ${({ disabled } : IButtonInterface) => (disabled ? '0.3' : '1')};
	cursor: pointer;
	display: block;
	border-radius: 4px;
	transition: all 0.3s linear;
	a {
		color: inherit;
		text-decoration: none;
		width: inherit;
		height: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export default RegularButton;
