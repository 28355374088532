import * as React from 'react';
import styled from 'styled-components';

const TimerWrapper = styled.div`
	width: 32px;
	height: 32px;
	margin: 10px auto;
	overflow: visible;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #393E3F;
	font-family: "The Sun";
	font-size: 13px;
	font-weight: bold;
	line-height: 18px;
	padding: 7px;
`;
class CountDown extends React.Component<any, any> {

	public state = {
		seconds: 0,
		completed: false,
		diff: 0
	};

	private timer: any;

	/**
	 * @ignore
	 */
	public componentWillMount() {

		let duration = this.props.seconds * 1000;
		this.diff(duration);

		if(duration < 1000) {
			this.handleCompleteState();
		}
		this.timer = setInterval(() => {
			duration = duration - 1000;
			this.diff(duration)
		}, 1000)
	}

	/**
	 * @ignore
	 */
	public componentWillUnmount() {
		this.setState ( {

			seconds: 0
		});
		clearInterval(this.timer)
	}

	/**
	 * @ignore
	 */
	public diff(diff: any) {
		if (diff < 1000) {
			this.handleCompleteState();
		} else {
			let seconds = Math.floor(diff / 1000);
			seconds = seconds % 60;
			this.setState({
				diff,
				seconds
			})

		}
	}

	/**
	 * @ignore
	 */
	public handleCompleteState(){
		const { onComplete } = this.props;
		this.setState({
			completed: true
		});
		clearInterval(this.timer);
		onComplete();
	}

	/**
	 * @ignore
	 */
	public render() {
		const { diff } = this.state;

		if(!diff) {
			return null;
		}
		return (

			<TimerWrapper>
				{this.state.seconds}
			</TimerWrapper>
		)
	}

}

export const CountdownSeconds = CountDown;

export default CountdownSeconds;