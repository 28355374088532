import bindAll from 'lodash/bindAll';
import partial from 'lodash/partial';
import React, { Component } from 'react';
import styled, { css } from "styled-components";
import { media } from "../../assets/css/media";
import { share } from "../../modules/utils/social_share";
import { Icon } from "../Icons";


interface IOwnProps {
	readonly color?: string
	readonly size?: string
	readonly disable?: boolean
	readonly section: 'general';
	readonly border_width?: string
	readonly margin?: string

}
interface IShareStyled {
	readonly size?: string
	readonly margin?: string
}
export const ShareStyled = styled.div`
	width: 100%;
	max-width: 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: ${({ size }: IShareStyled) => size || '1em'};
	margin: ${({ margin }: IShareStyled) => margin || '0 auto'};
	
	${({ size }: IShareStyled) => !size && css`
		@media (max-width:  ${media.desktop};) {
			max-width: 8.625em;
			padding: 0.5em 1.5em;
			font-size: 0.75em;
		}
	`}
	
`;

interface IIconWrapper {
	readonly color?: string
	readonly disable?: boolean
}
const IconWrapper = styled.button<IIconWrapper>`
	width: 45px;
	height: 45px;
	border-radius: 50%;
	display: block;
	cursor: ${({ disable }) => disable ? 'auto' : 'pointer'};
	transition: all 0.3s linear;
	font-size: 1em;
	outline: none;
	position: relative;
	background: ${props => props.theme.second_color};
	border: none;
	z-index: 3;
	&:hover {
		transform: translateY(-2px);
	}
	
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
type Props = IOwnProps;

class ShareComponent extends Component<Props> {
	constructor(props: Props) {
		super(props);

		bindAll(this, [
			'handleShare'
		])
	}
	/**
	 * Method for sharing Twitter and Facebook
	 */
	public handleShare(type_share: 'tw' | 'fb') {
		const { disable, section } = this.props;

		if(!disable) {
			share({
				sc: type_share,
				section
			});
		}

	}

	/**
	 * @ignore
	 */
	public render(): React.ReactNode {
		const { color, size, disable, margin } = this.props;
		return (
			<ShareStyled size={size} margin={margin}>
				<IconWrapper
					disable={disable}
					onClick={partial(this.handleShare, 'fb')}
					color={color}
				>
					<Icon name={'facebook'} size={'1.25em'} color={color || '#fff'}/>
				</IconWrapper>
				<IconWrapper
					disable={disable}
					onClick={partial(this.handleShare, 'tw')}
					color={color}
				>
					<Icon name={'twitter'} size={'1.25em'} color={color || '#fff'}/>
				</IconWrapper>
			</ShareStyled>
		)
	}
}

export const ShareLinks = ShareComponent;