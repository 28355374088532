import { SagaIterator } from 'redux-saga';
import { takeLatest }   from 'redux-saga/effects';
import * as actions     from '../actions';

import * as errors        from './error_managment';
import * as question from './question';

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
	/**
	 * JSONS
	 */
	yield takeLatest(actions.fetchQuestion, question.QuestionJSONSaga);
	yield takeLatest(actions.fetchCompetitionQuestion, question.FetchCompetitionQuestion);

	/**
	 * API
	 */
	yield takeLatest(actions.selectAnswer, question.SelectAnswerSaga);
	yield takeLatest(actions.postYCIAnswer, question.PostYCIAnswerSaga);
	yield takeLatest(actions.submitDataCapture, question.SubmitDataCaptureSaga);
	yield takeLatest(actions.submitPermission, question.SubmitPermissionSaga);

	yield takeLatest(actions.PostCompetitionAnswer, question.PostCompetitionAnswerSaga);

	// Errors
	yield takeLatest(actions.errorsGlobalError, errors.errorsManagerSaga);

}