import { Storage } from '../Storage'

const answerKey = (id: number) => 'answer-' + id;

export const saveQuestionToStorage = (question_id: number, answer_id: number) => {
	Storage.set(answerKey(question_id), answer_id);
};

export const getAnswerFromStorage = (question_id: number): number => {
	const answer = Storage.get(answerKey(question_id)) || '';

	return parseInt(answer, 10) || 0;
};

export const removeAnswerFromStorage = (question_id: number): void => {
	console.log(answerKey(question_id), getAnswerFromStorage(question_id));
	Storage.remove(answerKey(question_id));
};

const statsKey = (id: number) => 'stats-' + id;

export const saveStatsToStorage = (question_id: number, stats: any) => {
	Storage.set(statsKey(question_id), JSON.stringify(stats));
};


export const getStatsFromStorage = (question_id: number | undefined): any => {

	if(!question_id) {
		return {};
	}

	try {
		const answer = Storage.get(statsKey(question_id)) || '{}';

		return JSON.parse(answer);
	} catch ( e ) {
		console.log(e);
		clearStatsFromStorage(question_id);
		return getStatsFromStorage(question_id)
	}

};

export const clearStatsFromStorage = (id: number) => {
	Storage.remove(statsKey(id));
};