import styled from 'styled-components';

export const CompetitionButton = styled.button`
	border-radius: 3px;
	background-color: #EB1701;
	outline: none;
	border: none;
	font-size: 16px;
	font-weight: 500;
	line-height: 18px;
	text-align: center;
	color: #fff;
	height: 40px;
	cursor:pointer;
`;

export default CompetitionButton;